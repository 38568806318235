import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInputComponent from "../../../components/PhoneInputComp";
import { ages, countries, languages } from "../../../utils/helpers"; // Removed passport related imports
import FormikSelect from "../../../components/FormikFields/FormikSelect";
import FormikInput from "../../../components/FormikFields/FormikInput";
import ImageUpload from "../../../components/ImageUpload";
import SkillsSelection from "../../../components/FormikFields/FormikSkillsSelection";
import {
  getskillset,
  updateDataLakeWorkforce,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { adminPathUrl } from "../../../shared/pathUrl";
import { toast } from "react-toastify";

const UpdateDataLakeWorkForce = () => {
  const [previewImage, setPreviewImage] = useState(null);
  const [skillsList, setSkillsList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const workforceData = location?.state || {};
  console.log("skilllocaction", location?.state);
  const initialValues = {
    id: workforceData.id || "",
    firstName: (workforceData && workforceData?.fullName?.split(" ")[0]) || "",
    lastName: (workforceData && workforceData?.fullName?.split(" ")[1]) || "",
    email: workforceData.email || "",
    phone: workforceData.phone || "",
    profilePic: workforceData?.profile_pic,
    age: workforceData.age?.replace(" years", "") || "",
    nationality: workforceData.nationality || "",
    skill: workforceData.skill || [],
    countryCode: workforceData.countryCode || "",
    is_passport: workforceData.is_passport || "", // Initialize the passport field
    language: workforceData.language || "",
    city: workforceData.city || "",
    passport: workforceData.passport || "", // Initialize the passport selection
  };

  useEffect(() => {
    const fetchSkills = async () => {
      const skillDataArr = await getskillset({
        body: null,
        type: "datalake",
        id: workforceData.id,
      });
      console.log("dadasd", skillDataArr?.data);
      if (skillDataArr?.status) {
        setSkillsList(skillDataArr?.data);
      }
    };

    fetchSkills();
    if (workforceData) {
      setPreviewImage(workforceData.profile_pic);
    }
  }, [workforceData]);
  console.log("skilldata", skillsList);

  const handleSubmit = async (values) => {
    console.log("Submitting:", values); // Debugging line

    const data = {
      user_id: values.id,
      fullName: `${values.firstName} ${values.lastName}`,
      countryCode: values.countryCode,
      phone: values.phone,
      city: values.city,
      age: `${values.age} years`,
      nationality: values.nationality,
      // skill: values.skill.map((skill) => ({
      //   skillName: skill.skillName,
      //   SubSkillName: skill.subSkills[0]?.name
      //     ? skill.subSkills[0]?.name
      //     : skill?.SubSkillName,
      //   experience: skill.experience,
      //   subSkillId: skill.sub_id ? skill.sub_id : skill?.subSkillId,
      // })),
      skill: values?.skill?.map((item) => ({
        experience: item.experience,
        skillName: item.skillName,
        subSkillId: item.subSkillId,
        SubSkillName: item.subSkillName,
      })),
      is_passport: values.is_passport,
      language: values.language,
      updated_by: "admin",
    };
    console.log("oayload", data);
    try {
      const response = await updateDataLakeWorkforce(data);
      if (response?.status) {
        navigate(adminPathUrl.dataLakeWorkforceDetail, {
          state: {
            id: values?.id,
          },
        });
      } else {
        toast.error(response?.response?.data?.message);
      }
      console.log("Update Response:", response);
    } catch (error) {
      console.error("Update Error:", error);
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.firstName) errors.firstName = "First name is required";
    if (!values.lastName) errors.lastName = "Last name is required";
    if (!values.nationality) errors.nationality = "Nationality is required";
    if (!values.age) errors.age = "Age is required";
    // if (!values.skill || values.skill.length === 0) errors.skill = "Skills are required";
    if (!values.is_passport) errors.is_passport = "Passport is required";

    if (!values.language) errors.language = "Language is required";
    if (!values.phone) errors.phone = "Phone number is required";
    if (!values.city) errors.city = "City is required";
    return errors;
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("profilePic", file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onPhoneChange = (value, setFieldValue) => {
    setFieldValue("phone", value.number);
    setFieldValue("countryCode", value.countryCode);
  };

  return (
    <div className="add_user_page">
      <div className="card shadow p-5 m-5">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          enableReinitialize={true}
        >
          {({ isSubmitting, setFieldValue, values, errors }) => (
            <Form>
              {console.log("Submittingerr", values)}
              <div className="row">
                <ImageUpload
                  mainClass="col-md-4 form-group profile_pic_update"
                  previewConClass="img_block"
                  previewImage={previewImage}
                  placeholder="Profile Picture"
                  id="profilePic"
                  onChange={(event) => handleFileChange(event, setFieldValue)}
                  inputClass="form-control-file d-none"
                  name="profilePic"
                  label="Upload Profile"
                  labelClass="global_admin_btn"
                />
                <div className="col-md-8 ps-4">
                  <h2 className="text-center pb-4">Update Workforce</h2>
                  <div className="form-group">
                    <div className="form-row row">
                      <FormikInput
                        type="text"
                        id="firstName"
                        placeholder="First Name"
                        name="firstName"
                        fieldClass="form-control"
                        mainClass="col-md-6"
                        errName="firstName"
                      />
                      <FormikInput
                        type="text"
                        id="lastName"
                        placeholder="Last Name"
                        name="lastName"
                        fieldClass="form-control"
                        mainClass="col-md-6"
                        errName="lastName"
                      />
                    </div>
                  </div>
                  <div className="form-row row">
                    <div className="form-group col-md-6">
                      <PhoneInputComponent
                        id="phone"
                        name="phone"
                        onChange={(value) =>
                          onPhoneChange(value, setFieldValue)
                        }
                        value={`${initialValues.countryCode}${initialValues.phone}`}
                        errName="phone"
                      />
                    </div>
                    <FormikSelect
                      as="select"
                      id="age"
                      name="age"
                      mainClass="form-group col-md-6"
                      errName="age"
                      options={ages}
                      placeholder="Select Age"
                    />
                  </div>
                  <div className="form-row row">
                    <FormikSelect
                      as="select"
                      id="nationality"
                      name="nationality"
                      mainClass="form-group col-md-6"
                      errName="nationality"
                      options={countries}
                      placeholder="Select Nationality"
                    />
                    <FormikSelect
                      as="select"
                      id="is_passport"
                      name="is_passport"
                      mainClass="form-group col-md-6"
                      errName="is_passport"
                      options={[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" },
                      ]}
                    />
                  </div>
                  <div className="form-row row">
                    <FormikSelect
                      as="select"
                      id="language"
                      name="language"
                      mainClass="form-group col-md-6"
                      errName="language"
                      options={languages}
                      placeholder="Select Language"
                    />
                    <FormikInput
                      type="text"
                      id="city"
                      placeholder="City"
                      name="city"
                      fieldClass="form-control"
                      mainClass="col-md-6"
                      errName="city"
                    />
                  </div>
                  {skillsList?.length > 0 && (
                    <SkillsSelection
                      errName="skill"
                      data={skillsList?.map((skill) => ({
                        skillName: skill?.skillName,
                        subSkills:
                          skill?.subSkills &&
                          skill?.subSkills?.map((subSkill) => ({
                            name: subSkill.name,
                            selected: workforceData?.skill?.some(
                              (s) => s.SubSkillName === subSkill.name
                            ),
                            year:
                              workforceData?.skill
                                .find((s) => s.SubSkillName === subSkill.name)
                                ?.experience.replace(" years", "") || 0,
                            sub_id: subSkill.sub_id,
                          })),
                      }))}
                      onSelect={(selectedSkills) => {
                        setFieldValue("skill", selectedSkills);
                      }}
                    />
                  )}
                  <div className="text-center">
                    <button
                      type="submit"
                      className="global_admin_btn me-2"
                      // onClick={() => {
                      //   console.log("click----");
                      //   handleSubmit();
                      // }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submiting..." : "Update Workforce"}
                    </button>
                    <button
                      type="button"
                      className="global_admin_btn"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateDataLakeWorkForce;
