import React, { useState, useEffect, useCallback } from "react";
import { FaTrash, FaUser } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPathUrl } from "../../shared/pathUrl";
import { useDispatch } from "react-redux";
import { IoSearchSharp } from "react-icons/io5";
import {
  getDeleteWorkforce,
  getskillset,
  getworkforcelisting,
  verifyWorkforceUser,
} from "../../services/admin/manageworkforce/manageworkforceservices";
import DataTableComponent from "../../components/dataTable/dataTable";
import DeleteModalAdmin from "../../components/deleteModalAdmin";
import Loader from "../../components/loader/loader";
import { getCategory } from "../../services/web/manpowerEnquiry/manpowerEnquiryservices";
import "./workforce.css";
import "../../index.css";
import {
  debounce,
  downloadCSV,
  downloadPDF,
  getAllSkills,
} from "../../utils/methods";
import Avatar from "../../components/Avatar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import usePagination from "../../hooks/usePagination";
import useSearch from "../../hooks/useSearch";
import { fDateTime } from "../../shared/helperfunction";

const DataLakeWorkForce = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [skillFilter, setSkillFilter] = useState("");
  const [experienceFilter, setExperienceFilter] = useState("");
  const [listData, setListData] = useState([]);
  const [skillsetData, setSkillsetData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skillList, setSkillList] = useState([]);
  const [verificationFilter, setVerificationFilter] = useState("");
  const [userDeleteCall, setUserDeleteCall] = useState({
    deleteUser: false,
  });
  const { search, setSearch } = useSearch();
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formatDateTime = (date, options) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const userColumns = [
    {
      name: "",
      selector: (row) => <Avatar imgSrc={row.profile_pic} />,
    },
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
    },

    {
      name: "Phone",
      selector: (row) => {
        const countryCode = row.countryCode;
        const phoneNumber = row.phone;
        return `${countryCode} ${phoneNumber}`;
      },
      sortable: true,
    },
    {
      name: "Nationality",
      selector: (row) => row?.nationality,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row?.city,
      sortable: true,
    },
    {
      name: "Age",
      selector: (row) => row.age,
      sortable: true,
    },
    {
      name: "Skills",
      selector: (row) => (
        <div className="experience_col">
          {row.skills.map((item, index) => (
            <p key={index}>{item?.SubSkillName}</p>
          ))}
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Experience",
      selector: (row) => (
        <div className="experience_col">
          {row.skills.map((item, index) => (
            <p key={index}>{item.experience}</p>
          ))}
        </div>
      ),
      sortable: true,
      width: "110px",
    },
    {
      name: "Account Status",
      selector: (row) => (
        <span
          style={{
            color: row.account_status === "Active" ? "green" : "red",
          }}
        >
          {row.account_status}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => fDateTime(row.createdAt, "dd-MMM-yyyy"),
      sortable: true,
      width: "110px",
    },
    {
      name: "Time",
      selector: (row) => fDateTime(row.createdAt, "p"),
      sortable: true,
      width: "110px",
    },
    {
      name: "Verification Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.is_admin_verified === "Verified"
                ? "green"
                : row.is_admin_verified === "Unverified"
                ? "darkgoldenrod"
                : row.is_admin_verified === "Rejected"
                ? "red"
                : "black",
          }}
        >
          {row.is_admin_verified}
        </span>
      ),
      sortable: true,
      width: "150px",
    },
  ];

  const onVerifyUser = (row, value) => {
    const body = {
      is_admin_verified: value,
    };
    verifyWorkforceUser({ body, id: row._id }).then((res) => {
      if (res.status) {
        fetchWorkforceData();
      }
    });
  };

  useEffect(() => {
    const fetchSkillset = async () => {
      try {
        const data = await getskillset({ body: null, type: "data_lake" });
        setSkillsetData(data);
      } catch (error) {
        console.error("Error fetching skillset:", error);
      }
    };
    fetchSkillset();
  }, []);

  useEffect(() => {
    getCategory()
      .then((res) => setSkillList(res.data))
      .catch((err) => console.log(err));
  }, []);

  const onSearchHandler = async (search = "") => {
    try {
      const response = await getworkforcelisting({
        body: null,
        type: "data_lake",
        search: search,
      });
      if (response?.data && response?.status) {
        const withoutVerifiedUsers = response.data.data.filter(
          (item) => item.is_admin_verified !== "Verified"
        );
        setListData(withoutVerifiedUsers);
        setFilteredData(withoutVerifiedUsers);
        setPagination((prev) => {
          return {
            ...prev,
            totalPages: withoutVerifiedUsers.length,
            totalCount: withoutVerifiedUsers.length,
          };
        });
      }
    } catch (error) {
      console.error("Error fetching workforce data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  // Create a debounced version of the onSearchHandler
  const debouncedSearchHandler = useCallback(
    debounce(onSearchHandler, 300),
    []
  );


  const fetchWorkforceData = async (page, limit, search = "") => {
    if (search === "") {
      setLoading(true); // Set loading to true before fetching data
    }
    try {
      const response = await getworkforcelisting({
        body: null,
        type: "data_lake",
        page: page,
        limit: limit,
        search: search,
      });
      if (response?.data && response?.status) {
        const withoutVerifiedUsers = response.data.data.filter(
          (item) => item.is_admin_verified !== "Verified"
        );
        setListData(withoutVerifiedUsers);
        setFilteredData(withoutVerifiedUsers);
        setPagination((prev) => {
          return {
            ...prev,
            totalPages: response?.data?.totalPages,
            totalCount: response?.data?.totalUsers,
          };
        });
      }
    } catch (error) {
      console.error("Error fetching workforce data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const { pagination, setPagination } = usePagination();

  const { currentPage, limit } = pagination;

  const fetchDeleteUserData = async (search) => {
    try {
      setLoading(true);
      console.log("searcjinsise",search)
      const getData = await getDeleteWorkforce(currentPage, search);
      console.log("getdata", getData);
      if (getData?.data && getData?.status) {
        setListData(getData?.data?.data);
        setFilteredData(getData?.data?.data);
        setPagination((prev) => {
          return {
            ...prev,
            totalPages: getData?.data?.totalPages,
            totalCount: getData?.data?.totalUsers,
          };
        });
      }
      setLoading(false);
    } catch (error) {
      console.log("err", error);
    }
  };

  // create a debounced version for the delete user
  const debouncedSearchForDeleteUser = useCallback(
    debounce(fetchDeleteUserData, 300),
    []
  );

  useEffect(() => {
    fetchWorkforceData(currentPage, limit, search);
  }, [currentPage]);

  useEffect(() => {
    if (search === "" && !userDeleteCall?.deleteUser) {
      // call only when delete user is not clicked
      fetchWorkforceData(currentPage, limit, search);
    } else if (!userDeleteCall?.deleteUser) {
      debouncedSearchHandler(search);
    }
  }, [search]);

  useEffect(() => {
    if (userDeleteCall?.deleteUser) {
      console.log("search",search)
      if (search) {
        debouncedSearchForDeleteUser(search);
      } else {
        fetchDeleteUserData(search);
      }
    } else {
      fetchWorkforceData(currentPage, limit, search);
    }
  }, [userDeleteCall, search]);
  console?.log("userDeleteCall", userDeleteCall);

  useEffect(() => {
    let filtered = listData;

    // Filter by skill
    if (skillFilter) {
      filtered = filtered.filter((item) =>
        item.skills.some((skill) => skill.SubSkillName === skillFilter)
      );
    }

    // Filter by experience
    if (experienceFilter) {
      filtered = filtered.filter((item) => {
        const experienceYears = item.skills.reduce(
          (acc, skill) => acc + getExperienceYears(skill.experience),
          0
        );
        switch (experienceFilter) {
          case "0-1":
            return experienceYears >= 0 && experienceYears <= 1;
          case "1-2":
            return experienceYears > 1 && experienceYears <= 2;
          case "2-4":
            return experienceYears > 2 && experienceYears <= 4;
          case "4+":
            return experienceYears > 4;
          default:
            return true;
        }
      });
    }

    // Filter by search term
    // if (search) {
    //   filtered = filtered.filter(
    //     (item) =>
    //       item?.fullName?.toLowerCase()?.includes(search?.toLowerCase()) ||
    //       item?.phone?.toLowerCase()?.includes(search?.toLowerCase())
    //   );
    // }

    // Filter by verification status
    if (verificationFilter) {
      filtered = filtered.filter(
        (item) => item.is_admin_verified === verificationFilter
      );
    }

    setFilteredData(filtered);
  }, [skillFilter, experienceFilter, verificationFilter, listData]);

  const handleRowClicked = (row) => {
    // Include skills in the navigation state
    navigate(adminPathUrl.dataLakeWorkforceDetail, {
      state: {
        id: row._id,
        skills: row.skills, // Send the skills data here
        currentPage: currentPage,
      },
    });
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const handleAddUserClick = () => {
    navigate(adminPathUrl.addDataWorkforce);
  };

  const handleSkillFilterChange = (event) => {
    setSkillFilter(event.target.value);
  };

  const handleExperienceFilterChange = (event) => {
    setExperienceFilter(event.target.value);
  };

  const handleVerificationFilterChange = (event) => {
    setVerificationFilter(event.target.value);
  };

  const getExperienceYears = (skill) => {
    const match = skill.match(/(\d+) years/);
    return match ? parseInt(match[1], 10) : 0;
  };

  if (loading) {
    return <Loader />;
  }

  const subSkillNames = getAllSkills(skillsetData.data);
  const pdfHeaders = [
    "Name",
    "Phone",
    "Age",
    "Skills with Experience",
    "Account Status",
    "Date & Time",
    "Verification Status",
  ];

  console.log("skillsetData", skillsetData);

  const pdfData = filteredData.map((row) => [
    row.fullName,
    `${row.countryCode} ${row.phone}`,
    row.age,
    row.skills
      .map((item) => `${item.SubSkillName} with ${item.experience}`)
      .join(", "),
    row.account_status,
    formatDateTime(row.createdAt),
    row.is_admin_verified,
  ]);

  const pdfFileName = "Manage_profiles.pdf";
  const csvFileName = "Manage_profiles.csv";

  const csvData = filteredData.map((row) => ({
    Name: row.fullName,
    Phone: `${row.countryCode} ${row.phone}`,
    Age: row.age,
    Skills: row?.skills.map((item) => item?.skillName).toString(),
    Experince: row?.experience,
    Nationality: row?.nationality,
    City: row?.city,
    "Account Status": row.account_status,
    "Date & Time": formatDateTime(row.createdAt),
    "Verification Status": row.is_admin_verified,
  }));
  console.log("location", filteredData);
  return (
    <div className="user_page">
      <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
        <div className="mb-0">
          <input
            className="searchbar"
            type="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <IoSearchSharp className="searchbar-icon" size={44} />
        </div>

        <div className="filters_group text-end mt-2 col-md-6 gap-2">
          <FormControl fullWidth>
            <InputLabel id="skill-filter-label">Skill</InputLabel>
            <Select
              labelId="skill-filter-label"
              value={skillFilter}
              onChange={handleSkillFilterChange}
              label="Skill"
            >
              <MenuItem value="All Skills">All Skills</MenuItem>
              {skillsetData &&
                skillsetData?.data?.map((skill, index) => (
                  <MenuItem key={index} value={skill?.skillName}>
                    {skill?.skillName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="experience-filter-label">Experience</InputLabel>
            <Select
              labelId="experience-filter-label"
              value={experienceFilter}
              onChange={handleExperienceFilterChange}
              label="Experience"
            >
              <MenuItem value="">All Experiences</MenuItem>
              <MenuItem value="0-1">0-1 years</MenuItem>
              <MenuItem value="1-2">1-2 years</MenuItem>
              <MenuItem value="2-4">2-4 years</MenuItem>
              <MenuItem value="4+">4+ years</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="verification-filter-label">Verification</InputLabel>
            <Select
              labelId="verification-filter-label"
              value={verificationFilter}
              onChange={handleVerificationFilterChange}
              label="Verification"
            >
              <MenuItem value="">All Verification Status</MenuItem>
              {/* <MenuItem value="Verified">Verified</MenuItem> */}
              <MenuItem value="Unverified">Unverified</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>

          <button
            onClick={() =>
              navigate(adminPathUrl.addDataWorkforce, { state: skillList })
            }
            className="add-user-button m-0 d-flex align-items-center gap-2"
          >
            <FaUser /> <span>Add Workforce</span>
          </button>
        
        </div>
      </div>

      <div className="datatable-section manage-card">
        <DataTableComponent
          toRedirectPage={location?.state?.currentPage}
          title={
            <>
              {/* {userDeleteCall?.deleteUser === "deleteUser" ? (
                <button
                  onClick={() =>
                    setUserDeleteCall((prev) => ({
                      ...prev,
                      deleteUser: "",
                    }))
                  }
                  className="global_admin_btn"
                >
                  back
                </button>
              ) : null}{" "} */}
              {userDeleteCall?.deleteUser ? "Deleted User" : "Data Lake"}
            </>
          }
          data={filteredData}
          columns={userColumns}
          selectedRows={selectedRows}
          clickedrow={handleRowClicked}
          checkboxchange={handleCheckboxChange}
          responsive={true}
          downloadBtns={true}
          downloadCSV={() => downloadCSV(csvData, csvFileName)}
          downloadPDF={() => downloadPDF(pdfHeaders, pdfData, pdfFileName)}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>

      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure to delete this Worker"}
      />
    </div>
  );
};

export default DataLakeWorkForce;
